.swiper-container {
  margin-top: -2em;
  padding-top: 2em !important;

  .swiper-controls {
    font-size: 24px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;

    .swiper-button-next,
    .swiper-button-prev {
      @include media-breakpoint-up(sm) {
        top: 3px;
      }
      color: $black;
      display: inline-block;
      left: auto;
      position: relative;
      right: auto;
      top: 4px;
      width: auto;

      &::after {
        content: none;
      }
    }

    .swiper-pagination {
      @include media-breakpoint-up(sm) {
        padding: 0 7px;
      }
      display: inline-block;
      position: relative;

      .swiper-pagination-bullet {
        @include media-breakpoint-up(sm) {
          height: 12px;
          margin: 0 3px;
          width: 12px;
        }
        height: 10px;
        margin: 0 2px;
        width: 10px;
      }

      .swiper-pagination-bullet-active {
        background-color: $black;
      }
    }
  }

  &.essay-series {
    article {
      display: flex;

      .feature-content-topic-list {
        @include link($color: $cigi-primary-colour-grey-bg, $hover-color: $cigi-dark-pink);
      }

      p {
        @include link($color: $cigi-primary-colour-grey-bg, $hover-color: $cigi-dark-pink);
      }
    }

    .essay-series-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .article-series-pdf {
        ul {
          list-style: none;
          display: flex;
          margin: 0;

          li {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}
