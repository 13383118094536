@import '../../../css/global/mixins';
@import '../../../css/includes/swiper';

.homepage {
  hr {
    &.has-margin {
      @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  &:not(.with-banner) {
    .homepage-featured {
      margin-bottom: 3rem;
      padding-top: 5rem;
    }
  }

  .homepage-header-newsletter {
    @include media-breakpoint-up(lg) {
      padding-top: 5em;
    }
    line-height: 1.25;
    margin-bottom: 2em;
    padding-top: 4em;
    padding-bottom: 1.5rem;
    background: $black;

    .summary {
      p {
        @include media-breakpoint-up(md) {
          font-size: 1.25em;
        }
        font-size: 1.0625em;
        padding-bottom: 1rem;
      }
    }

    p {
      @include media-breakpoint-up(md) {
        text-align: center;
      }
      @include media-breakpoint-up(md) {
        font-size: 0.875rem;
      }
      font-size: 1.0625em;
      color: $white;
      margin-bottom: 0;
      font-weight: 300;

      &.newsletter {
        @include link($color: $white, $hover-color: $cigi-primary-colour);

        a {
          font-weight: 500;
          text-decoration: underline;
        }
      }

      &.curated-content {
        a {
          &:hover {
            svg {
              fill: $cigi-primary-colour;
            }
          }
          
          svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: $white;
            transition: fill 0.2s ease;
          }
        }
      }
    }
  }

  .homepage-featured {
    margin-bottom: 3em;
  }

  .homepage-featured-experts,
  .homepage-featured-multimedia,
  .homepage-featured-publications,
  .homepage-featured-highlights,
  .homepage-featured-events {
    @include media-breakpoint-up(md) {
      margin-bottom: 4em;
    }
    margin-bottom: 3em;
  }

  .homepage-featured-experts {
    .homepage-featured-expert-row {
      @include media-breakpoint-up(md) {
        border-right: 1px solid $cigi-light-grey;
        padding-right: 0;
      }

      &:last-child {
        @include media-breakpoint-up(md) {
          border-right: 0;
          padding-right: 15px;
        }

        article {
          border-bottom: 0;
          border-right: 0;
        }
      }

      article {
        @include media-breakpoint-up(md) {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          padding-right: 1em;
        }
        border-bottom: 1px solid $cigi-light-grey;
        margin-bottom: 1.5em;
        padding-bottom: 1.5em;
      }
    }
  }

  .homepage-featured-multimedia {
    @include media-breakpoint-up(md) {
      padding-bottom: 4em;
      padding-top: 4em;
    }
    background-color: $black;
    padding-bottom: 3em;
    padding-top: 3em;
  }

  .homepage-featured-publications {
    .homepage-featured-publication-row {
      display: flex;

      &:last-child {
        article {
          @include media-breakpoint-down(sm) {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .homepage-featured-highlights {
    h2 {
      margin-bottom: 1em;
    }
  }

  .homepage-featured-events {
    .homepage-featured-event-row {
      @include media-breakpoint-up(md) {
        border-right: 1px solid $cigi-light-grey;
        padding-right: 0;
      }

      &:last-child {
        @include media-breakpoint-up(md) {
          border-right: 0;
          padding-right: 15px;
        }

        article {
          border-bottom: 0;
          border-right: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      article {
        @include media-breakpoint-up(md) {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          padding-right: 1em;
        }
        border-bottom: 1px solid $cigi-light-grey;
        margin-bottom: 1.5em;
        padding-bottom: 1.5em;
      }
    }
  }

  .homepage-subheading {
    @include link($color: $black, $hover-color: $cigi-primary-colour);
    color: $black;
    font-size: 0.75em;
    margin-bottom: 1.5em;
    margin-top: 0.3em;
    text-transform: uppercase;

    &.dark-mode {
      @include link($color: $white, $hover-color: $cigi-primary-colour);
      color: $white;

      a,
      span {
        border-top-color: $white;
      }
    }

    a,
    span {
      border-top-color: $black;
      border-top-style: solid;
      border-top-width: 3px;
      transition: border-top-color 0.2s ease;
    }

    a {
      &:hover {
        border-top-color: $cigi-primary-colour;
      }
    }
  }

  .homepage-promotion-blocks {
    margin-bottom: 2em;

    .homepage-promotion-block {
      @include media-breakpoint-up(md) {
        margin-bottom: 2em;
      }
      margin-bottom: 1em;

      &.promotion-block-wide {
        flex-basis: 100%;
      }
    }
  }
}
